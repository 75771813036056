@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html, body {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-display: optional;
}

body {
  background: #1c2e4a;
  color: #ffffff;
  font-size: 1rem;
}

a {
  color: #ffffff;

  &:visited {
    color: #cccccc;
  }
}

ul {
  text-align: left;

  li {
    line-height: 2rem;
  }
}

.app {
  text-align: center;
}
